<template>
  <form>
    <div class="animated fadeIn">
      <b-card class="px-md-2">
        <ResponseAlert ref="response" />
        <h3>{{ $route.meta.breadcrumb[1].text }}</h3>
        <b-row class="mt-2">
          <b-col
            cols="12"
          >
            <b-row>
              <b-col :md="POStillWaiting ? 10 : 6">
                <b-row
                  class="my-1"
                  align-v="center"
                >
                  <b-col sm="4">
                    <label
                      class="h5 font-weight-light"
                      for="name"
                    >No Order</label>
                  </b-col>
                  <b-col sm="7">
                    <h5
                      class="font-weight-bold"
                      v-text="models.poNumber"
                    />
                  </b-col>
                </b-row>
                <b-row
                  class="my-1"
                  align-v="center"
                >
                  <b-col sm="4">
                    <label
                      class="h5 font-weight-light"
                      for="name"
                    >Status</label>
                  </b-col>
                  <b-col sm="7">
                    <h5
                      class="font-weight-bold"
                      v-text="models.statusChild"
                    />
                  </b-col>
                </b-row>
                <b-row
                  v-if="POStillWaiting"
                  class="my-1"
                  align-v="center"
                >
                  <b-col sm="4">
                    <label
                      class="h5 font-weight-light"
                      for="name"
                    >Pemesan</label>
                  </b-col>
                  <b-col sm="7">
                    <h5
                      class="font-weight-bold"
                      v-text="models.requestBy.name"
                    />
                  </b-col>
                </b-row>
                <b-row
                  v-if="!POStillWaiting"
                  class="my-1"
                  align-v="center"
                >
                  <b-col sm="4">
                    <label
                      class="h5 font-weight-light"
                      for="name"
                    >{{ statusComplaint ? 'KFS Pemesan' : 'Orderan Untuk' }}</label>
                  </b-col>
                  <b-col sm="7">
                    <h5
                      class="font-weight-bold"
                      v-text="models.requestBy.name"
                    />
                  </b-col>
                </b-row>
                <b-row
                  v-if="POStillWaiting"
                  class="my-1"
                  align-v="center"
                >
                  <b-col sm="4">
                    <label
                      class="h5 font-weight-light"
                      for="name"
                    >Alamat</label>
                  </b-col>
                  <b-col sm="7">
                    <h5
                      class="font-weight-bold"
                      v-text="models.requestBy.address"
                    />
                  </b-col>
                </b-row>
                <b-row
                  v-if="POStillWaiting"
                  class="my-1"
                  align-v="center"
                >
                  <b-col sm="4">
                    <label
                      class="h5 font-weight-light"
                      for="name"
                    >No Telepon</label>
                  </b-col>
                  <b-col sm="7">
                    <h5
                      class="font-weight-bold"
                      v-text="models.requestBy.phone"
                    />
                  </b-col>
                </b-row>
                <b-row
                  class="my-1"
                  align-v="center"
                >
                  <b-col sm="4">
                    <label
                      class="h5 font-weight-light"
                      for="name"
                    >Tipe Stok</label>
                  </b-col>
                  <b-col sm="7">
                    <h5 class="font-weight-bold">
                      <h5
                        class="font-weight-bold"
                        v-text="models.typeText"
                      />
                    </h5>
                  </b-col>
                </b-row>
              </b-col>
              <b-col
                v-if="!POStillWaiting"
                md="6"
              >
                <b-row
                  class="my-1"
                  align-v="center"
                >
                  <b-col sm="4">
                    <label
                      class="h5 font-weight-light"
                      for="name"
                    >Pemesan</label>
                  </b-col>
                  <b-col sm="7">
                    <h5
                      class="font-weight-bold"
                      v-text="models.requestBy.name"
                    />
                  </b-col>
                </b-row>
                <b-row
                  class="my-1"
                  align-v="center"
                >
                  <b-col sm="4">
                    <label
                      class="h5 font-weight-light"
                      for="name"
                    >Alamat</label>
                  </b-col>
                  <b-col sm="7">
                    <h5
                      class="font-weight-bold"
                      v-text="models.requestBy.address"
                    />
                  </b-col>
                </b-row>
                <b-row
                  class="my-1"
                  align-v="center"
                >
                  <b-col sm="4">
                    <label
                      class="h5 font-weight-light"
                      for="name"
                    >No Telepon</label>
                  </b-col>
                  <b-col sm="7">
                    <h5
                      class="font-weight-bold"
                      v-text="models.requestBy.phone"
                    />
                  </b-col>
                </b-row>
                <b-button
                  v-if="models.status === 1"
                  type="button"
                  variant="primary"
                  class="btn-min-width rounded"
                  @click.prevent="downloadInvoice"
                >
                  Download Invoice
                </b-button>
              </b-col>
            </b-row>
          </b-col>
          <b-col
            :md="POStillWaiting ? 10 : 12"
          >
            <b-row
              class="mt-1"
              align-v="center"
            >
              <b-col sm="12">
                <h4>Rincian Order</h4>
                <p v-if="POStillWaiting">
                  Silahkan ceklis produk untuk memastikan ketersediaan produk
                </p>
              </b-col>
            </b-row>
            <hr v-if="POStillWaiting">
            <div v-if="POStillWaiting">
              <b-row
                v-for="item, idx in models.items"
                :key="idx"
                align-v="center"
              >
                <b-col
                  sm="9"
                  lg="7"
                >
                  <b-row style="padding: 8px 0">
                    <b-col sm="12">
                      <h6 class="mb-0">
                        {{ item.name ? item.name : item.detail ? item.detail.name : '-'}}
                      </h6>
                    </b-col>
                  </b-row>
                  <b-row
                    style="background-color: #eee; padding: 8px 0"
                  >
                    <b-col cols="8">
                      Rp. {{ parseFloat(item.unitPrice).toLocaleString().replace(/[.,]/g, m => m === '.' ? ',' : '.') }}
                    </b-col>
                    <b-col cols="4">
                      <div class="d-flex align-items-center">
                        <b-button
                          size="sm"
                          variant="primary"
                          style="padding: 7px; line-height: 7px"
                          class="rounded-circle"
                          @click="removeProduct(item)"
                        >
                          -
                        </b-button>
                        <p class="px-1 mb-0">
                          {{ renderQty(item.id) }}
                        </p>
                        <b-button
                          size="sm"
                          variant="primary"
                          style="padding: 7px; line-height: 7px"
                          class="rounded-circle"
                          @click="pushProduct(item)"
                        >
                          +
                        </b-button>
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row
                class="mt-2"
                align-v="center"
              >
                <b-col
                  lg="9"
                >
                  <b-row
                    class="py-1"
                  >
                    <b-col cols="7">
                      Total Keseluruhan
                    </b-col>
                    <b-col cols="5">
                      <h5>Rp. {{ parseFloat(totalPrice).toLocaleString().replace(/[.,]/g, m => m === '.' ? ',' : '.') }}</h5>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row class="d-flex justify-content-center my-1 mb-2">
                <b-col md="9">
                  <router-link
                    :to="{name: 'detail-purchase-order-add-product', params: { id: $route.params.id, items: models.items, type: models.typeText, kfsId} }"
                    class="btn btn-primary display-table-cell pull-right rounded mb-1 mb-md-0 btn-width-responsive"
                  >
                    <feather-icon
                      icon="PlusIcon"
                      size="12"
                    /> Tambah Produk Lainnya
                  </router-link>
                </b-col>
              </b-row>
              <b-row
                align-v="center"
                class="mb-1"
              >
                <b-col md="3">
                  <label
                    for="tambahan"
                    class="font-weight-bold"
                  >
                    Biaya Tambahan
                  </label>
                </b-col>
                <b-col md="5">
                  <b-form-input
                    id="tambahan"
                    v-model="models.addPayment"
                    type="number"
                  />
                </b-col>
              </b-row>
              <b-row
                align-v="start"
              >
                <b-col md="3">
                  <label
                    for="keterangan"
                    class="font-weight-bold"
                  >
                    Keterangan
                  </label>
                </b-col>
                <b-col md="5">
                  <b-form-textarea
                    id="keterangan"
                    v-model="models.addPaymentNote"
                    rows="3"
                  />
                </b-col>
              </b-row>
            </div>
            <div v-else>
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th
                      width="30%"
                      scope="col"
                    >
                      Nama Barang
                    </th>
                    <th
                      width="10%"
                      scope="col"
                    >
                      Qty
                    </th>
                    <th
                      width="30%"
                      scope="col"
                    >
                      Harga
                    </th>
                    <th
                      width="30%"
                      scope="col"
                    >
                      Total
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="product, index in models.items"
                    :key="index"
                  >
                    <td>
                      {{ product.detail ? product.detail.name : product.name || '' }}
                    </td>
                    <td>{{ product.qty }}</td>
                    <td>
                      Rp. {{ parseFloat(product.unitPrice).toLocaleString().replace(/[.,]/g, m => m === '.' ? ',' : '.') }}
                    </td>

                    <td>
                      Rp. {{ (parseFloat(product.unitPrice) * parseFloat(product.qty)).toLocaleString().replace(/[.,]/g, m => m === '.' ? ',' : '.') }}
                    </td></tr>
                  <tr>
                    <td />
                    <td />
                    <th>Subtotal</th>
                    <td>
                      Rp. {{ parseFloat(models.totalPayment).toLocaleString().replace(/[.,]/g, m => m === '.' ? ',' : '.') }}
                    </td>
                  </tr>
                  <tr v-if="!statusComplaint">
                    <td />
                    <td />
                    <th>Biaya Tambahan</th>
                    <td>
                      Rp. {{ parseFloat(models.addPayment).toLocaleString().replace(/[.,]/g, m => m === '.' ? ',' : '.') }}
                    </td>
                  </tr>
                  <tr v-if="!statusComplaint">
                    <td />
                    <td />
                    <th>Keterangan</th>
                    <td>{{ models.addPaymentNote }}</td>
                  </tr>
                  <tr v-if="!statusComplaint">
                    <td />
                    <td />
                    <th>Total Keseluruhan</th>
                    <td>
                      Rp. {{ (parseFloat(models.totalPayment) + parseFloat(models.addPayment)).toLocaleString().replace(/[.,]/g, m => m === '.' ? ',' : '.') }}
                    </td>
                  </tr>
                  <tr v-if="statusComplaint">
                    <td />
                    <td />
                    <th>Keterangan Komplain</th>
                    <td>{{ models.complaint.complain }}</td>
                  </tr>
                  <tr v-if="statusComplaint">
                    <td />
                    <td />
                    <th>Attachment</th>
                    <td>
                      <ul>
                        <li
                          v-for="attachment, index in models.complaint.attachments"
                          :key="index"
                        >
                          <a
                            target="_blank"
                            :href="attachment"
                          >{{ attachment }}</a>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-col>
        </b-row>
        <b-row
          v-if="POStillWaiting"
          class="mt-5"
        >
          <b-col
            md="7"
          >
            <div class="d-flex justify-content-center justify-content-md-end">
              <b-button
                type="button"
                variant="light"
                class="btn-min-width rounded btn-secondary"
                @click="$router.push(config.uri)"
              >
                Kembali
              </b-button>
              <b-button
                type="button"
                variant="light"
                class="btn-min-width btn-warning rounded mx-1"
                @click="rejectPO"
              >
                Tolak
              </b-button>
              <b-button
                type="button"
                variant="primary"
                class="btn-min-width rounded"
                @click.prevent="acceptPO"
              >
                Terima
              </b-button>
            </div>
          </b-col>
        </b-row>
        <div
          v-else-if="statusComplaint && buttonText !== ''"
          class="d-flex justify-content-end mt-5"
        >
          <b-button
            type="button"
            variant="light"
            class="btn-min-width rounded btn-secondary mr-1"
            @click="$router.push(config.uri)"
          >
            Kembali
          </b-button>
          <b-button
            type="button"
            variant="primary"
            class="btn-min-width rounded"
            @click.prevent="changeStatusComplaint"
          >
            {{ buttonText }}
          </b-button>
        </div>
        <div
          v-else-if="models.status !== -2 && buttonText !== ''"
          class="d-flex justify-content-end mt-5"
        >
          <b-button
            type="button"
            variant="light"
            class="btn-min-width rounded btn-secondary mr-1"
            @click="$router.push(config.uri)"
          >
            Kembali
          </b-button>
          <b-button
            type="button"
            variant="primary"
            class="btn-min-width rounded"
            @click.prevent="changeStatus"
          >
            {{ buttonText }}
          </b-button>
        </div>
        <div
          v-else
          class="d-flex justify-content-end mt-5"
        >
          <b-button
            type="button"
            variant="light"
            class="btn-min-width rounded btn-secondary mr-1"
            @click="$router.push(config.uri)"
          >
            Kembali
          </b-button>
        </div>
      </b-card>
    </div>
  </form>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BFormInput, BFormTextarea,
} from 'bootstrap-vue'
import api from '@/utils/api'
// eslint-disable-next-line import/no-cycle
import { getUrlOfFile, exportPDF } from '@/utils/helpers'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'FormPurchaseOrder',
  metaInfo: {
    title: 'Form PO',
  },
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BFormTextarea,
  },
  data() {
    return {
      config: {
        uri: `/${this.$route.meta.link}`,
      },
      kfsId: null,
      models: {
        items: [],
        acceptedBy: '',
        acceptedBys: '',
        acceptedTo: '',
        date: '',
        internalStatus: '',
        statusChild: '',
        totalPayment: '',
        typeText: '',
        poNumber: '',
        products: '',
        reason: '',
        requestBy: '',
        status: 2,
        addPayment: null,
        addPaymentNote: '',
        complaint: '',
      },
      // Functions
      getUrlOfFile,
    }
  },
  computed: {
    totalPrice() {
      return this.models.items.map(item => item.unitPrice * item.qty).reduce((total, amount) => total + amount, 0)
    },

    POStillWaiting() {
      return this.models.status === 0
    },

    statusComplaint() {
      return this.models.status === -3
    },

    buttonText() {
      let text = ''
      switch (this.models.status) {
        case 1:
          text = 'Pembayaran Diterima'
          break
        case 2:
          text = 'Barang Sudah Dikirim'
          break
        case -3:
          if (this.models.complaint.status === 0) {
            text = 'Proses'
          } else if (this.models.complaint.status === 1) {
            text = 'Komplain selesai diproses'
          }
          break
        default:
          break
      }
      return text
    },
  },
  mounted() {
    this.getById()
  },
  methods: {
    getById() {
      if (this.$route.params.id) {
        this.models.items = []
        this.$axios.get(`${api.purchaseOrder}/${this.$route.params.id}`)
          .then(res => {
            const { data } = res.data
            this.kfsId = data.kfsId
            this.models.acceptedBy = data.acceptedBy
            this.models.acceptedBys = data.acceptedBys
            this.models.acceptedTo = data.acceptedTo
            this.models.date = data.date
            this.models.internalStatus = data.internalStatus
            this.models.statusChild = data.statusChild
            this.models.totalPayment = data.totalPayment
            this.models.typeText = data.typeText
            this.models.poNumber = data.poNumber
            this.models.reason = data.reason
            this.models.requestBy = data.requestBy
            this.models.status = data.status
            this.models.addPayment = data.addPayment
            this.models.addPaymentNote = data.addPaymentNote || ''
            if (this.models.status !== 0) {
              this.models.addPayment = data.addPayment || 0
            }
            if (this.models.status === -3) {
              this.models.complaint = data.complaints[0]
            }

            if (this.$route.params.items) {
              this.models.items = this.$route.params.items
            } else {
              data.products.forEach(product => {
                product.id = product.accuProductId
                this.models.items.push(product)
              })
              data.raws.forEach(raw => {
                raw.id = raw.accuRawMaterialId
                this.models.items.push(raw)
              })
            }
          }).catch(() => this.$router.push({
            name: 'page404',
          }))
      }
    },
    downloadInvoice() {
      exportPDF('Invoice Purchase Order', `${api.purchaseOrder}/${this.$route.params.id}/invoice`)
    },
    renderQty(id) {
      const itemIdx = this.models.items.findIndex(model => model.id === id)
      return itemIdx > -1 ? this.models.items[itemIdx].qty : 0
    },
    pushProduct(item) {
      const itemIdx = this.models.items.findIndex(model => model.id === item.id)
      if (itemIdx > -1) {
        this.models.items[itemIdx].qty += 1
        this.models.items.push(item)
        this.models.items.pop()
      } else {
        item.qty = 1
        this.models.items.push(item)
      }
    },

    removeProduct(item) {
      const itemIdx = this.models.items.findIndex(model => (model.id === item.id) && (model.no === item.no))
      if (itemIdx > -1) {
        if (this.models.items[itemIdx].qty > 0) {
          this.models.items[itemIdx].qty -= 1
          if (this.models.items[itemIdx].qty === 0) {
            this.models.items = this.models.items.filter((item, idx) => idx !== itemIdx)
          }
        }

        this.models.items.push(item)
        this.models.items.pop()
      }
    },

    changeStatus() {
      let status
      switch (this.models.status) {
        case 1:
          // Change status to menunggu diproses
          status = 2
          break
        case 2:
          // Change status to sedang diproses
          status = 3
          break
        default:
          status = 4
          break
      }
      this.$axios.put(`${api.purchaseOrder}/${this.$route.params.id}/status`, {
        status,
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success update status PO',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
        this.getById()
        window.scrollTo(0, 0)
      })
    },
    changeStatusComplaint() {
      let status
      switch (this.models.complaint.status) {
        case 0:
          // Change status to menunggu diproses
          status = 1
          break
        case 1:
          // Change status to sedang diproses
          status = 2
          break
        default:
          break
      }
      this.$axios.put(`${api.purchaseOrder}/${this.$route.params.id}/status-complain`, {
        status,
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success update status complaint',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
        this.getById()
        window.scrollTo(0, 0)
      })
    },

    acceptPO() {
      let productsRaws = this.models.items.map(item => {
        const value = {
          unitPrice: item.unitPrice,
          qty: item.qty,
        }

        if (this.models.typeText === 'Produk') {
          value.accuProductId = item.accuProductId || item.id
        } else {
          value.accuRawMaterialId = item.accuRawMaterialId || item.id
        }

        return value
      })

      if (!productsRaws.length) {
        productsRaws = null
      }

      const models = {
        addPayment: this.models.addPayment || 0,
        addPaymentNote: this.models.addPaymentNote,
        status: 1,
      }

      if (this.models.typeText === 'Produk') {
        models.products = productsRaws
      } else {
        models.raws = productsRaws
      }

      this.$axios.put(`${api.purchaseOrder}/${this.$route.params.id}/accept`, models).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success approve purchase order',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
        this.getById()
        window.scrollTo(0, 0)
      }).catch(error => this.$refs.response.setResponse(error.response.data, 'danger'))
    },

    rejectPO() {
      this.$swal({
        title: 'Perhatian',
        text: 'Apakah anda yakin akan menolak permintaan ini?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$axios.put(`${api.purchaseOrder}/${this.$route.params.id}/status`, { status: -2 })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Success declined purchase order',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              })
              this.getById()
              window.scrollTo(0, 0)
            })
            .catch(error => this.$refs.response.setResponse(error.response.data, 'danger'))
        }
      })
    },
  },
}
</script>

<style>

</style>
